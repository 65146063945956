import { IV3Project } from '../server/src/models'
import { defaultInstrument, defaultLayer } from '../utils/instrumentsUtils'
import { copyObj } from '../utils/stringUtils'
import {
  Chord,
  defaultChord,
  defaultProg,
  getDefaultPart,
  Lyrics,
  Prog,
  ProgDb,
  Project,
  TMobileTab,
  ViewConfig,
} from '../utils/types'
import api from './index'

const needSetup = (part: any, key: string) => !(key in part) || part[key] === undefined

const progToProgressionJson = (prog: Prog) => {
  const newProg = JSON.parse(JSON.stringify(prog)) as ProgDb

  prog.parts.forEach((part, p_i) => {
    part.chordLayers.forEach((chordLayer, cl_i) => {
      newProg.parts[p_i].chordLayers[cl_i].instrument = chordLayer.instrument.key
      newProg.parts[p_i].chordLayers[cl_i].playstyle = chordLayer.playstyle.id
    })

    part.melodyLayers.forEach((melodyLayer, ml_i) => {
      newProg.parts[p_i].melodyLayers[ml_i].instrument = melodyLayer.instrument.key
      newProg.parts[p_i].melodyLayers[ml_i].playstyle = melodyLayer.playstyle.id
    })

    part.drumLayers.forEach((drumLayer, dl_i) => {
      newProg.parts[p_i].drumLayers[dl_i].instrument = drumLayer.instrument.key
      newProg.parts[p_i].drumLayers[dl_i].playstyle = drumLayer.playstyle.id
    })
  })

  return newProg
}

export const progressionJsonToProg = (progDb: ProgDb) => {
  const newProg = { ...defaultProg, ...(progDb as any as Prog) }

  progDb.parts.forEach((part, p_i) => {
    let { chordLayers, melodyLayers, drumLayers } = part
    const newProgPart = newProg.parts[p_i]

    // GENERAL

    if (needSetup(newProgPart, 'id')) newProgPart.id = Math.max(...progDb.parts.map((p) => p.id || 0), 0) + 1
    if (needSetup(newProgPart, 'name')) newProgPart.name = newProgPart.id % 2 ? 'Verse' : 'Chorus'
    if (needSetup(newProgPart, 'generatorSettings'))
      newProgPart.generatorSettings = { chordGenreKey: 'pop', drumGenreKey: 'badroom-pop' }
    if (needSetup(newProgPart, 'loops')) newProgPart.loops = 1

    // CHORDS

    if (needSetup(newProgPart, 'chords')) newProgPart.chords = []
    if (needSetup(newProgPart, 'chordsVolume')) newProgPart.chordsVolume = 100
    if (needSetup(newProgPart, 'chordsMuted')) newProgPart.chordsMuted = false
    if (needSetup(newProgPart, 'chordLayers')) {
      chordLayers = [copyObj(defaultLayer)]
      chordLayers[0].instrument = 'cinematic-piano'
      chordLayers[0].playstyle = 'basic'

      newProgPart.chordLayers = newProgPart.chordLayers || chordLayers
    }

    newProgPart.chords = newProgPart.chords.map((chord, index) => {
      const newChord = { ...defaultChord, ...chord }

      if (!newChord.id) newChord.id = newProgPart.id * 1000 + index + 1
      if (!newChord.voicings.length) newChord.voicings = [{ name: 'Default', midi: newChord.midi }]

      return newChord
    })

    chordLayers.forEach((chordLayer, cl_i) => {
      newProgPart.chordLayers[cl_i] = { ...copyObj(defaultLayer), ...chordLayer }
      newProgPart.chordLayers[cl_i].instrument = { ...defaultInstrument, key: chordLayer.instrument }
      newProgPart.chordLayers[cl_i].playstyle = { id: chordLayer.playstyle || 'basic', name: '', types: [] }
    })

    // MELODY

    if (needSetup(newProgPart, 'melody')) newProgPart.melody = null
    if (needSetup(newProgPart, 'melodyVolume')) newProgPart.melodyVolume = 100
    if (needSetup(newProgPart, 'melodyMuted')) newProgPart.melodyMuted = false
    if (needSetup(newProgPart, 'melodyShown')) newProgPart.melodyShown = !!newProgPart.melody
    if (needSetup(newProgPart, 'melodyLayers')) {
      melodyLayers = [copyObj(defaultLayer)]
      melodyLayers[0].instrument = 'cinematic-piano'
      melodyLayers[0].playstyle = 'basic'

      newProgPart.melodyLayers = newProgPart.melodyLayers || melodyLayers
    }

    melodyLayers.forEach((melodyLayer, ml_i) => {
      newProgPart.melodyLayers[ml_i] = { ...copyObj(defaultLayer), ...melodyLayer }
      newProgPart.melodyLayers[ml_i].instrument = { ...defaultInstrument, key: melodyLayer.instrument }
      newProgPart.melodyLayers[ml_i].playstyle = { id: melodyLayer.playstyle || 'basic', name: '', types: [] }
    })

    // DRUMS

    if (needSetup(newProgPart, 'drums')) newProgPart.drums = null
    if (needSetup(newProgPart, 'drumsVolume')) newProgPart.drumsVolume = 100
    if (needSetup(newProgPart, 'drumsMuted')) newProgPart.drumsMuted = false
    if (needSetup(newProgPart, 'drumsShown')) newProgPart.drumsShown = !!newProgPart.drums
    if (needSetup(newProgPart, 'drumLayers')) {
      drumLayers = [copyObj(defaultLayer)]
      drumLayers[0].instrument = 'lighter'
      drumLayers[0].playstyle = 'basic'

      newProgPart.drumLayers = newProgPart.drumLayers || drumLayers
    }

    drumLayers.forEach((drumLayer, dl_i) => {
      newProgPart.drumLayers[dl_i] = { ...copyObj(defaultLayer), ...drumLayer }
      newProgPart.drumLayers[dl_i].instrument = { ...defaultInstrument, key: drumLayer.instrument }
      newProgPart.drumLayers[dl_i].playstyle = { id: drumLayer.playstyle || 'basic', name: '', types: [] }
    })
  })

  return newProg
}

export const getChord = (id: number, name: string, degree: string, midi: number[]): Chord => {
  return {
    name,
    midi,
    degree,
    midiTimings: [],
    settings: {
      velocity: 100,
    },
    duration: 1,
    voicings: [
      {
        name: 'Default',
        midi,
      },
    ],
    id,
    octave: undefined,
  }
}

export const getFirstVisitProject = (STRINGS: { [key: string]: any }): IV3Project => {
  const prog = {
    ...defaultProg,
    componentKey: Math.random(),
    name: STRINGS.welcomeName,
    parts: [
      {
        ...getDefaultPart(true),
        id: 1,
        name: STRINGS.verse,
      },
    ],
  }

  prog.parts[0].chords = [
    getChord(1001, 'C', 'I', [36, 52, 55]),
    getChord(1002, 'G', 'V', [43, 50, 55, 59]),
    getChord(1003, 'Am', 'vi', [33, 45, 52, 57, 60]),
    getChord(1004, 'F', 'IV', [41, 57, 60, 65, 69]),
  ]

  prog.parts[0].chordLayers = [copyObj(defaultLayer)]
  prog.parts[0].chordLayers[0].instrument.key = 'cinematic-piano'

  prog.parts[0].melodyLayers = [copyObj(defaultLayer)]
  prog.parts[0].melodyLayers[0].instrument.key = 'cinematic-piano'

  prog.parts[0].drumLayers = [copyObj(defaultLayer)]
  prog.parts[0].drumLayers[0].instrument.key = 'lighter'

  return progressionToDbProject(prog)
}

export const progressionToDbProject = (prog: Prog, view?: ViewConfig, lyrics?: Lyrics) => {
  return {
    name: prog.name,
    prog: progToProgressionJson(prog),
    lyrics,
    view: {
      piano_open: view?.pianoOpen,
      guitar_open: view?.guitarOpen,
      notation_open: view?.notationOpen,
      editor_open: view?.editorOpen,
      generator_open: view?.generatorOpen,
      drums_open: view?.drumsOpen,
      lyrics_open: view?.lyricsOpen,
      drums_editor_open: view?.drumsEditorOpen,
      mobile_tab: view?.mobileTab,
      onboarding_open: view?.onboardingOpen,
      melody_editor_open: view?.melodyEditorOpen,
    },
  } as IV3Project
}

export function projectDbToProgression(project: IV3Project): null | Project {
  if (!project) return null

  let prog = null

  try {
    prog = progressionJsonToProg(project.prog)
  } catch (e) {
    console.error(e)
  }

  if (!prog) return null

  return {
    id: project._id,
    name: project.name!,
    prog,
    lyrics: project.lyrics,
    view: {
      guitarOpen: !!project.view?.guitar_open,
      notationOpen: !!project.view?.notation_open,
      pianoOpen: !!project.view?.piano_open,
      editorOpen: !!project.view?.editor_open,
      layersOpen: null,
      generatorOpen: !!project.view?.generator_open,
      drumsOpen: !!project.view?.drums_open,
      lyricsOpen: !!project.view?.lyrics_open,
      drumsEditorOpen: !!project.view?.drums_editor_open,
      mobileTab: (project.view?.mobile_tab || 'chords') as TMobileTab,
      onboardingOpen: !!project.view?.onboarding_open,
      melodyEditorOpen: !!project.view?.melody_editor_open,
    },
    updatedAt: project.updated_at,
  }
}

export async function saveProjectInnerRoute(prog: Prog, view: ViewConfig, lyrics: Lyrics): Promise<IV3Project> {
  const data = await api.post('/api/projects', {
    project: progressionToDbProject(prog, view, lyrics),
  })
  return data.data as IV3Project
}

export async function editProjectInnerRoute(
  id: string,
  prog: Prog,
  view: ViewConfig,
  lyrics: Lyrics,
): Promise<IV3Project> {
  const data = await api.put(`/api/projects/${id}`, {
    project: progressionToDbProject(prog, view, lyrics),
  })
  return data.data as IV3Project
}

export async function saveDraftProjectInnerRoute(
  prog?: Prog | null,
  view?: ViewConfig,
  lyrics?: Lyrics,
): Promise<IV3Project> {
  const data = await api.post('/api/projects/draft', {
    project: prog ? progressionToDbProject(prog, view, lyrics) : null,
  })
  return data.data as IV3Project
}

export async function cloneProjectInnerRoute(id: string, name?: string): Promise<IV3Project> {
  const data = await api.post(`/api/projects/${id}/clone`, { name })
  return data.data as IV3Project
}

export async function deleteProjectInnerRoute(id: string): Promise<IV3Project> {
  const data = await api.delete(`/api/projects/${id}`)
  return data.data as IV3Project
}

export const getProjectsInnerRoute = async ({
  size,
  page,
  search,
}: {
  size: number
  page: number
  search?: string
}) => {
  const data = await api.get('/api/projects', {
    params: { size, page, search: search || '' },
  })
  return {
    items: data.data?.items.map(projectDbToProgression).filter(Boolean),
    total: data.data?.total,
  }
}

export const getProjectByIdInnerRoute = async (id: string) => {
  const data = await api.get(`/api/projects/${id}`)
  return projectDbToProgression(data.data)
}

export const getRecentProjectsInnerRoute = async () => {
  const data = await api.get('/api/projects/recent')
  return data.data?.map(projectDbToProgression)
}

export const getDraftProjectInnerRoute = async () => {
  const data = await api.get('/api/projects/draft')
  return {
    project: projectDbToProgression(data.data.project),
  }
}

export async function saveShareValueInnerRoute(projectId: string, isShareEnabled: boolean): Promise<IV3Project> {
  const data = await api.post('/api/projects/share', {
    projectId,
    isShareEnabled,
  })
  return data.data
}
