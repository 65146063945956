import { TFrontendLimit } from '../context/LimitsContext'
import { LimitTypesLabel } from '../utils/limits'
import { trackPixelEvent } from '../utils/pixelUtils'
import api from './index'

export type TryPlatinumLimitSource = 'limit' | LimitTypesLabel | TFrontendLimit
export enum TryPlatinumDefaultSource {
  'click_try_platinum' = 'click_try_platinum',
  'welcome_modal' = 'welcome_modal',
  'pricing' = 'pricing',
}
export type TryPlatinumSource = TryPlatinumLimitSource | keyof typeof TryPlatinumDefaultSource

type MixpanelClientSideEvent =
  // Tracks
  | 'add_new_song_part'
  | 'add_track'
  | 'add_chord_plus_click'
  | 'add_melody_plus_click'
  | 'add_drums_plus_click'
  // Instruments
  | 'change_instrument'
  | 'add_instrument_layer'
  | 'click_random_instrument'
  // Editors view
  | 'open_chord_editor'
  | 'select_chord_palette_mode'
  | 'open_melody_piano_roll'
  | 'open_drums_simple_mode'
  | 'open_drums_manual_mode'
  //
  | 'drums_simple_change_pattern'
  | 'pick_chord_from_palette'
  | 'accept_drums_autocomplete'

export const trackPlatinumModalOpen = async (openedBy: TryPlatinumSource) => {
  await api.post('/api/track-pricing/platinum-modal-open', {
    opened_by: openedBy,
  })
}

export const trackDefaultScreen = async () => {
  await api.post('/api/track-pricing/default-screen')
}

export const trackPricingScreen = async (plan: string) => {
  trackPixelEvent('InitiateCheckout')
  await api.post('/api/track-pricing/pricing-screen', { plan })
}

export const trackPaymentScreen = async (plan: string) => {
  await api.post('/api/track-pricing/payment-screen', { selected_plan: plan })
}

export const trackMixpanelEvent = async (eventName: MixpanelClientSideEvent, options: any) => {
  await api.post('/api/track', { eventName, options })
}

export const trackPlatinumModalPlanClick = async (plan: string) => {
  trackPixelEvent('InitiateCheckout')
  await api.post('/api/track-pricing/platinum-modal-plan-click', { plan })
}
