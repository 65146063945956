import React from 'react'

import LockIcon from '../../../assets/icons/lock.svg'
import { useCommonModals } from '../../../context/CommonModalsContext'
import { useCurrentUser } from '../../../context/CurrentUserContext'
import { useInternationalization } from '../../../context/InternationalizationContext'
import { LimitTypesLabel } from '../../../utils/limits'
import IconMenuButton from '../IconMenuButton'
import styles from './BlurModal.module.scss'
import UpgradeBg from './assets/upgrade-bg.svg'

type Props = {
  upgradeOnly?: boolean
  upgradeText: string
  loginText: string
  btnText?: string
  hideBorder?: boolean
  title: LimitTypesLabel
  onClick?: () => void
}

const BlurModal: React.FC<Props> = ({ hideBorder, btnText, onClick, loginText, upgradeText, title, upgradeOnly }) => {
  const { currentUser } = useCurrentUser()
  const modalsConfig = useCommonModals()
  const { text } = useInternationalization()
  const STRINGS = text.modals.blur
  if (!currentUser && !upgradeOnly) {
    return (
      <div className={styles.wrapper} data-login={true}>
        <div className={styles.border} />
        <div></div>
        <div className={styles.content}>
          <p className={styles.description}>
            <span>{STRINGS.loginToAccount}</span>
            {loginText}
          </p>
          <IconMenuButton
            text={STRINGS.login}
            icon='user'
            className={styles.loginButton}
            onClick={() => {
              modalsConfig.setLoginOpen(true)
              onClick && onClick()
            }}
          />
        </div>
      </div>
    )
  }
  return (
    <div className={styles.wrapper}>
      {!hideBorder && <div className={styles.border} />}
      <div className={styles.content}>
        <UpgradeBg className={styles.upgradeBg} />
        <p className={styles.description}>
          <LockIcon />
          {STRINGS.upgradePlan.upgrade1}
          <span>{STRINGS.upgradePlan.upgrade2}</span>
          {upgradeText}
        </p>
        <IconMenuButton
          text={btnText || (currentUser?.prefs?.isTrialUsed ? STRINGS.upgrade : STRINGS.tryFree)}
          icon='platinum'
          className={styles.upgradePlatinumButton}
          onClick={() => {
            modalsConfig.setTryPlatinumUpgradeTitle(title)
            modalsConfig.setTryPlatinumOpen(currentUser ? 'upgrade' : 'default', 'limit')
            onClick && onClick()
          }}
        />
      </div>
    </div>
  )
}

export default BlurModal
