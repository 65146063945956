import classNames from 'classnames'
import React from 'react'

import HarmonizeMinusOneIcon from '../../../../assets/icons/harmonize-minus-one.svg'
import HarmonizePlusMinusOneIcon from '../../../../assets/icons/harmonize-plus-minus-one.svg'
import HarmonizePlusOneIcon from '../../../../assets/icons/harmonize-plus-one.svg'
import useSizes from '../../../../hooks/useSizes'
import { updateProgPartDataById } from '../../../../utils/progUtils'
import Tooltip from '../../../common/Tooltip/Tooltip'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import styles from './EditorSettings.module.scss'

const STRINGS = {
  title: 'Harmonize melody:',
}

const harmonizeOptions = [
  {
    icon: null,
    name: 'OFF',
    tooltip: '',
  },
  {
    icon: <HarmonizePlusOneIcon />,
    name: '+1',
    tooltip: '+1 Octave',
  },
  {
    icon: <HarmonizePlusMinusOneIcon />,
    name: '+-1',
    tooltip: '+-1 Octave',
  },
  {
    icon: <HarmonizeMinusOneIcon />,
    name: '-1',
    tooltip: '-1 Octave',
  },
]
export const defaultHarmonizeOption = harmonizeOptions[0].name
export const getHarmonizeOption = (optionName?: string) => {
  const harmonizeOptionsNames = harmonizeOptions.map((opt) => opt.name)

  if (optionName && !harmonizeOptionsNames.includes(optionName)) return harmonizeOptions[0].name
  return optionName || harmonizeOptions[0].name
}

const HarmonizeSetting = () => {
  const { isMobile } = useSizes()
  const {
    playerConfig: { prog, currentPartId, currentPart },
    playerConfigSetter: { setProg },
  } = usePlayerConfigState()

  const activeOption = getHarmonizeOption(currentPart.melody?.harmonize)
  const activeOptionIndex = harmonizeOptions.findIndex((option) => option.name === activeOption)

  const handleUpdateOption = (optionName: string) => {
    if (!prog || !currentPart.melody) return

    const newProg = updateProgPartDataById(prog, currentPartId, {
      melody: { ...currentPart.melody, harmonize: optionName },
    })
    setProg(newProg)
  }

  return (
    <div className={styles.harmonizeSetting}>
      <div className={styles.harmonizeSettingTitle}>{STRINGS.title}</div>

      <div className={styles.harmonizeSettingContent}>
        <div
          className={styles.harmonizeSettingMarker}
          style={{
            width: isMobile ? `calc((100vw - 16px * 2) / ${harmonizeOptions.length})` : 64,
            left: isMobile
              ? `calc((100vw - 16px * 2) / ${harmonizeOptions.length} * ${activeOptionIndex})`
              : activeOptionIndex * 64,
          }}
        />

        {harmonizeOptions.map((option, index) => {
          const tooltipId = `harmonize-option-${option.name}-tooltip`

          return (
            <React.Fragment key={option.name}>
              {index !== 0 && (
                <div
                  className={classNames(styles.harmonizeSettingDivider, {
                    [styles.harmonizeSettingDividerHidden]:
                      index === activeOptionIndex || index === activeOptionIndex + 1,
                  })}
                />
              )}

              <div
                className={styles.harmonizeSettingOption}
                onClick={() => handleUpdateOption(option.name)}
                data-tooltip-id={tooltipId}
              >
                {option.icon && option.icon}
                <span>{option.name}</span>
              </div>

              {option.tooltip && (
                <Tooltip id={tooltipId}>
                  <>{option.tooltip}</>
                </Tooltip>
              )}
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default HarmonizeSetting
