import getConfig from 'next/config'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { ReactNode, useEffect, useState } from 'react'

import { trackPlatinumModalOpen, TryPlatinumSource } from '../api/tracking'
import TryPlatinumModal from '../components/layout/CommonModals/TryPlatinumModal/TryPlatinumModal'
import LoginModal, { limitTypeType } from '../components/signup/login/LoginModal/LoginModal'
import WelcomeModal from '../components/signup/welcome/WelcomeModal'
import { hjEvent } from '../utils/hotjar'
import { getLimitTypeLabelToLimit, LimitTypesLabel } from '../utils/limits'
import { FrontendUserDetailed } from '../utils/types'
import { useCurrentUser } from './CurrentUserContext'
import { useInternationalization } from './InternationalizationContext'

const FeedbackModal = dynamic(() =>
  import('../components/editor/Footer/FeedbackModal/FeedbackModal').then((module) => module.FeedbackModal),
)
const CancelSequenceModal = dynamic(() =>
  import('../components/settings/CancelSequenceContainer/CancelSequenceModal').then(
    (module) => module.CancelSequenceModal,
  ),
)

// TODO: remove with TryPlatinumModal
const { publicRuntimeConfig } = getConfig() || {}
type TryPlatinumOpenType = 'default' | 'upgrade' | 'direct-yearly' | 'direct-monthly' | null

type FetchType = {
  // Login
  loginOpen: boolean
  setLoginOpen: (a?: boolean) => void
  loginLimitName: LimitTypesLabel | undefined
  setLoginLimitName: (a: LimitTypesLabel | undefined) => void
  loginLimitType: limitTypeType
  setLoginLimitType: (a: limitTypeType) => void
  // Welcome
  welcomeOpen: boolean
  setWelcomeOpen: (v: boolean) => void
  // Pricing
  pricingOpen: boolean

  // TODO: remove with TryPlatinumModal
  tryPlatinumOpen: TryPlatinumOpenType
  setTryPlatinumOpen: (a: TryPlatinumOpenType, b?: TryPlatinumSource) => void
  setTryPlatinumUpgradeTitle: (a?: LimitTypesLabel) => void
  //
  feedbackIsOpen: boolean
  setFeedbackIsOpen: (a?: boolean) => void
  cancelSequenceIsOpen: boolean
  setCancelSequenceIsOpen: (a?: boolean) => void
  launchpadOpen: boolean
  setLaunchpadOpen: (a: boolean) => void
}

export const CommonModalsContext = React.createContext<FetchType>({
  loginOpen: false,
  setLoginOpen: () => {},
  loginLimitName: undefined,
  setLoginLimitName: () => {},
  loginLimitType: undefined,
  setLoginLimitType: () => {},
  feedbackIsOpen: false,
  setFeedbackIsOpen: () => {},
  cancelSequenceIsOpen: false,
  setCancelSequenceIsOpen: () => {},
  launchpadOpen: false,
  setLaunchpadOpen: () => {},
  welcomeOpen: false,
  setWelcomeOpen: () => {},
  pricingOpen: false,
  tryPlatinumOpen: null,
  setTryPlatinumOpen: () => {},
  setTryPlatinumUpgradeTitle: () => {},
})

export const CommonModalsProvider = ({
  children,
  generatorComponent,
  previewComponent,
}: {
  children: ReactNode
  generatorComponent?: boolean
  previewComponent?: boolean
  drumsPosition?: { top: number; left: number }
  chordsPosition?: { top: number; left: number }
}) => {
  const router = useRouter()
  const { addComponentText } = useInternationalization()
  const { currentUser } = useCurrentUser() as {
    currentUser: FrontendUserDetailed
  }

  // Login
  const [loginOpen, setLoginOpen] = useState(false)
  const [loginLimitName, setLoginLimitName] = useState<LimitTypesLabel | undefined>(undefined)
  const [loginLimitType, setLoginLimitType] = useState<limitTypeType>(undefined)
  // Welcome
  const [welcomeOpen, setWelcomeOpen] = useState(false)
  // Pricing
  const [pricingOpen, setPricingOpen] = useState(false)
  const [pricingLimited, setPricingLimited] = useState(false)
  // TODO: remove with TryPlatinumModal
  const [tryPlatinumOpen, setTryPlatinumOpenRaw] = useState<TryPlatinumOpenType>(null)
  const [tryPlatinumUpgradeTitle, setTryPlatinumUpgradeTitle] = useState<LimitTypesLabel | undefined>(undefined)
  //
  const [launchpadOpen, setLaunchpadOpen] = useState<boolean>(false)
  const [feedbackIsOpen, setFeedbackIsOpen] = useState(false)
  const [cancelSequenceIsOpen, setCancelSequenceIsOpen] = useState(false)

  // TODO: remove with TryPlatinumModal
  const urlParams = new URLSearchParams(router.asPath.split('?')[1])
  const pricing = urlParams.get('pricing')

  useEffect(() => {
    addComponentText('CommonModalsContext')
  }, [])
  useEffect(() => {
    if (currentUser && (!currentUser.prefs?.quizTaken || !currentUser.prefs?.afterRegModalShown)) {
      hjEvent('event', 'quiz_open')
      setWelcomeOpen(true)
    }
  }, [])
  useEffect(() => {
    if (!pricing) return

    setTimeout(() => {
      if (pricing === 'initial') {
        setTryPlatinumOpen('default', 'pricing')
      }
      if (pricing === 'yearly') {
        setTryPlatinumOpen('direct-yearly', 'pricing')
      }
      if (pricing === 'monthly') {
        setTryPlatinumOpen('direct-monthly', 'pricing')
      }
    }, 1000)
  }, [pricing])

  const setTryPlatinumOpen = (type: TryPlatinumOpenType, source?: TryPlatinumSource) => {
    trackPlatinumModalOpen(source || 'click_try_platinum')

    setTryPlatinumOpenRaw(type)

    // setPricingOpen(true)
    // setPricingLimited(!!source && !Object.values(TryPlatinumDefaultSource).includes(source as any))
  }

  return (
    <CommonModalsContext.Provider
      value={{
        loginOpen,
        setLoginOpen: (val) => setLoginOpen(!!val),
        loginLimitName,
        setLoginLimitName,
        loginLimitType,
        setLoginLimitType,
        feedbackIsOpen,
        setFeedbackIsOpen: (val) => setFeedbackIsOpen(!!val),
        cancelSequenceIsOpen,
        setCancelSequenceIsOpen: (val) => setCancelSequenceIsOpen(!!val),
        launchpadOpen,
        setLaunchpadOpen,
        welcomeOpen,
        setWelcomeOpen,
        pricingOpen,
        tryPlatinumOpen,
        setTryPlatinumOpen,
        setTryPlatinumUpgradeTitle,
      }}
    >
      <LoginModal
        opened={loginOpen}
        onClose={() => {
          setLoginOpen(false)
          setTimeout(() => {
            setLoginLimitName(undefined)
            setLoginLimitType(undefined)
          }, 300)
        }}
        limitName={loginLimitName && getLimitTypeLabelToLimit(loginLimitName)}
        limitType={loginLimitType}
      />
      <WelcomeModal
        opened={welcomeOpen}
        onClose={() => {
          setWelcomeOpen(false)
          setTimeout(() => setTryPlatinumOpen('default', 'welcome_modal'), 300)
        }}
      />
      {/* <PricingModal
        opened={pricingOpen}
        isLimited={pricingLimited}
        onClose={() => {
          setPricingOpen(false)
          setPricingLimited(false)
        }}
      /> */}
      <TryPlatinumModal
        onClose={() => setTryPlatinumOpenRaw(null)}
        open={!!tryPlatinumOpen}
        paymentProvider={publicRuntimeConfig.MAIN_PAYMENT_PROVIDER}
        upgradeMode={tryPlatinumOpen === 'upgrade'}
        tabState={tryPlatinumOpen === 'direct-monthly' || tryPlatinumOpen === 'direct-yearly' ? 'PRICING' : undefined}
        upgradeModeTitle={tryPlatinumUpgradeTitle}
      />

      <FeedbackModal
        generatorComponent={generatorComponent}
        isOpen={feedbackIsOpen}
        setFeedbackIsOpen={setFeedbackIsOpen}
        onClose={() => setFeedbackIsOpen(false)}
      />
      <CancelSequenceModal
        isOpen={cancelSequenceIsOpen}
        onClose={() => {
          setCancelSequenceIsOpen(false)
        }}
      />
      {children}
    </CommonModalsContext.Provider>
  )
}

export const useCommonModals = () => React.useContext<FetchType>(CommonModalsContext)
